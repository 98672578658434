import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faRocket, faRoad, faTachometerAlt, faCar, faSquare } from '@fortawesome/free-solid-svg-icons'
const Paketpriser = () => (
  <Layout>
    <Seo title='Paketpriser' />
    <PageHeader text='Paketpriser' />
    <div className='container'>
      <div className='row justify-content-center'>

        <div className='col-12 text-center mt-2'>
          <span className='fa-stack fa-2x'>
            <FontAwesomeIcon icon={faSquare} className='fa-stack-2x' color='rgb(172, 3, 11)' />
            <FontAwesomeIcon icon={faRocket} className='fa-stack-1x fa-inverse' color='rgb(220,53,69)' />
          </span>
          <h1>Startpaketen</h1>
        </div>
        {/* START Lilla Startpaket */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-5'>
          <div className='card shadow mb-2'>
            <div className='card-header themeColorOrginal text-center text-white'>
              <h2 className='mb-2 text-white font-weight-normal'>Lilla <br /> startpaketet</h2>
            </div>
            <div className='card-body'>
              <p className='card-title mb-0 text-center' style={{color: 'rgb(0, 0, 0)', fontSize:'25px', textDecoration: 'line-through'}}>3 400 kr</p>
              <p className='card-text text-center'><i>En bra start på din utbildning</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vår och sommarerbjudande!</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>5 % rabatt</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i det här paketet</i></p>
              <br></br>
              <h3 className='card-title mb-0 text-center' style={{ color: 'rgb(172, 3, 11)' }}>3 265 kr</h3>
            </div>
            <ul className='list-group list-group-flush'>
              <li className='list-group-item py-2'> <FontAwesomeIcon icon={faCheck} color='green' /> 3 st körlektioner á 50 min</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Riskutbildning del 1</li>
              {/* <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Körkort nu: Allt - för bil</li> */}
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faTimes} color='red' /> Riskutbildning del 2</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faTimes} color='red' /> Trafikskolans fordon på prov</li>
            </ul>
            <div className='card-footer text-center'>
              {/* <Link to='/e-handel/productDetails/5448' className='btn btn-danger'>Beställ här</Link> */}
              <Link to='/e-handel' className='btn btn-danger'>Beställ här</Link>
            </div>
          </div>
        </div>
        {/* SLUT Lilla Startpaket */}

        {/* START Mellan Startpaket */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-5'>
          <div className='card shadow mb-2'>
            <div className='card-header themeColorOrginal text-center text-white'>
              <h2 className='mb-2 text-white font-weight-normal'>Mellan <br /> startpaketet</h2>
            </div>
            <div className='card-body'>
            <p className='card-title mb-0 text-center' style={{color: 'rgb(0, 0, 0)', fontSize:'25px', textDecoration: 'line-through'}}>5 200 kr</p>
              <p className='card-text text-center'><i>En bra start på din utbildning</i></p>
              {/* <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vintererbjudande 20% rabatt</i></p> */}
              {/* <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i paketet</i></p> */}
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vår och sommarerbjudande!</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>5 % rabatt</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i det här paketet</i></p>
              <br></br>
              <h3 className='card-title mb-0 text-center' style={{ color: 'rgb(172, 3, 11)' }}>4 975 kr</h3>
            </div>
            <ul className='list-group list-group-flush'>
              <li className='list-group-item py-2'> <FontAwesomeIcon icon={faCheck} color='green' /> 5 st körlektioner á 50 min</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Riskutbildning del 1</li>
              {/* <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Körkort nu: Allt - för bil</li> */}
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faTimes} color='red' /> Riskutbildning del 2</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faTimes} color='red' /> Trafikskolans fordon på prov</li>
            </ul>
            <div className='card-footer text-center'>
              {/* <Link to='/e-handel/productDetails/5451' className='btn btn-danger'>Beställ här</Link> */}
              <Link to='/e-handel' className='btn btn-danger'>Beställ här</Link>
            </div>
          </div>
        </div>
        {/* SLUT Mellan Startpaket */}

        {/* START Stora Startpaket */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'>
          <div className='card shadow mb-2'>
            <div className='card-header themeColorOrginal text-center text-white'>
              <h2 className='mb-2 text-white font-weight-normal'>Stora <br /> startpaketet</h2>
            </div>
            <div className='card-body'>
            <p className='card-title mb-0 text-center' style={{color: 'rgb(0, 0, 0)', fontSize:'25px', textDecoration: 'line-through'}}>9 700 kr</p>
              <p className='card-text text-center'><i>En bra start på din utbildning</i></p>
              {/* <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vintererbjudande 20% rabatt</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i paketet</i></p> */}
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vår och sommarerbjudande!</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>5 % rabatt</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i det här paketet</i></p>
              <br></br>
              <h3 className='card-title mb-0 text-center' style={{ color: 'rgb(172, 3, 11)' }}>9 250 kr</h3>
            </div>
            <ul className='list-group list-group-flush'>
              <li className='list-group-item py-2'> <FontAwesomeIcon icon={faCheck} color='green' /> 10 st körlektioner á 50 min</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Riskutbildning del 1</li>
              {/* <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Körkort nu: Allt - för bil</li> */}
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faTimes} color='red' /> Riskutbildning del 2</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faTimes} color='red' /> Trafikskolans fordon på prov</li>
            </ul>
            <div className='card-footer text-center'>
              {/* <Link to='/e-handel/productDetails/5452' className='btn btn-danger'>Beställ här</Link> */}
              <Link to='/e-handel' className='btn btn-danger'>Beställ här</Link>
            </div>
          </div>
        </div>
        {/* SLUT stora Startpaket */}

        <div className='col-12 text-center mt-2'>
          <span className='fa-stack fa-2x'>
            <FontAwesomeIcon icon={faSquare} className='fa-stack-2x' color='rgb(172, 3, 11)' />
            <FontAwesomeIcon icon={faTachometerAlt} className='fa-stack-1x fa-inverse' color='rgb(220,53,69)' />
          </span>
          <h1>Intensivpaketen</h1>
        </div>
        {/* START Lilla intensivpaketet */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-5'>
          <div className='card shadow mb-2'>
            <div className='card-header themeColorOrginal text-center text-white'>
              <h2 className='mb-2 text-white font-weight-normal'>Lilla <br />intensivpaketet</h2>
            </div>
            <div className='card-body'>
              <p className='card-title mb-0 text-center' style={{color: 'rgb(0, 0, 0)', fontSize:'25px', textDecoration: 'line-through'}}>13 700 kr</p>
              <p className='card-text text-center'><i>Planeras intensivt mot prov</i></p>
              {/* <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vintererbjudande 20% rabatt</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i paketet</i></p> */}
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vår och sommarerbjudande!</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>5 % rabatt</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i det här paketet</i></p>
              <br></br>
              <h3 className='card-title mb-0 text-center' style={{ color: 'rgb(172, 3, 11)' }}>13 250 kr</h3>
            </div>
            <ul className='list-group list-group-flush'>
              <li className='list-group-item py-2'> <FontAwesomeIcon icon={faCheck} color='green' /> 10 st körlektioner á 50 min</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Riskutbildning del 1</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Riskutbildning del 2</li>
              {/* <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Körkort nu: Allt - för bil</li> */}
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Trafikskolans fordon på prov</li>
            </ul>
            <div className='card-footer text-center'>
              {/* <Link to='/e-handel/productDetails/5455' className='btn btn-danger'>Beställ här</Link> */}
              <Link to='/e-handel' className='btn btn-danger'>Beställ här</Link>
            </div>
          </div>
        </div>
        {/* SLUT Lilla intensivpaketet */}

        {/* START Mellan intensivpaketet */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-5'>
          <div className='card shadow mb-2'>
            <div className='card-header themeColorOrginal text-center text-white'>
              <h2 className='mb-2 text-white font-weight-normal'>Mellan <br /> intensivpaketet</h2>
            </div>
            <div className='card-body'>
              <p className='card-title mb-0 text-center' style={{color: 'rgb(0, 0, 0)', fontSize:'25px', textDecoration: 'line-through'}}>22 700 kr</p>
              <p className='card-text text-center'><i>Planeras intensivt mot prov</i></p>
              {/* <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vintererbjudande 20% rabatt</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i paketet</i></p> */}
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vår och sommarerbjudande!</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>10 % rabatt</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i det här paketet</i></p>
              <br></br>
              <h3 className='card-title mb-0 text-center' style={{ color: 'rgb(172, 3, 11)' }}>20 900 kr</h3>
            </div>
            <ul className='list-group list-group-flush'>
              <li className='list-group-item py-2'> <FontAwesomeIcon icon={faCheck} color='green' /> 20 st körlektioner á 50 min</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Riskutbildning del 1</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Riskutbildning del 2</li>
              {/* <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Körkort nu: Allt - för bil</li> */}
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Trafikskolans fordon på prov</li>
            </ul>
            <div className='card-footer text-center'>
              {/* <Link to='/e-handel/productDetails/5457' className='btn btn-danger'>Beställ här</Link> */}
              <Link to='/e-handel' className='btn btn-danger'>Beställ här</Link>
            </div>
          </div>
        </div>
        {/* SLUT Mellan intensivpaketet */}

        {/* START Stora intensivpaketet */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'>
          <div className='card shadow mb-2'>
            <div className='card-header themeColorOrginal text-center text-white'>
              <h2 className='mb-2 text-white font-weight-normal'>Stora <br /> intensivpaketet</h2>
            </div>
            <div className='card-body'>
              <p className='card-title mb-0 text-center' style={{color: 'rgb(0, 0, 0)', fontSize:'25px', textDecoration: 'line-through'}}>31 700 kr</p>
              <p className='card-text text-center'><i>Planeras intensivt mot prov</i></p>
              {/* <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vintererbjudande 20% rabatt</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i paketet</i></p> */}
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vår och sommarerbjudande!</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>15 % rabatt</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i det här paketet</i></p>
              <br></br>
              <h3 className='card-title mb-0 text-center' style={{ color: 'rgb(172, 3, 11)' }}>27 650 kr</h3>
            </div>
            <ul className='list-group list-group-flush'>
              <li className='list-group-item py-2'> <FontAwesomeIcon icon={faCheck} color='green' /> 30 st körlektioner á 50 min</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Riskutbildning del 1</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Riskutbildning del 2</li>
              {/* <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Körkort nu: Allt - för bil</li> */}
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Trafikskolans fordon på prov</li>
            </ul>
            <div className='card-footer text-center'>
              {/* <Link to='/e-handel/productDetails/5459' className='btn btn-danger'>Beställ här</Link> */}
              <Link to='/e-handel' className='btn btn-danger'>Beställ här</Link>
            </div>
          </div>
        </div>
        {/* SLUT Stora intensivpaketet */}

        <div className='col-12 text-center  mt-2'>
          <span className='fa-stack fa-2x'>
            <FontAwesomeIcon icon={faSquare} className='fa-stack-2x' color='rgb(172, 3, 11)' />
            <FontAwesomeIcon icon={faRoad} className='fa-stack-1x fa-inverse' color='rgb(220,53,69)' />
          </span>
          <h1>Körpaketen</h1>
        </div>

        {/* START Körpaket 1  */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-5'>
          <div className='card shadow mb-2'>
            <div className='card-header themeColorOrginal text-center text-white'>
              <h2 className='mb-2 text-white font-weight-normal'>Körpaket 1 <br /> <p /></h2>
            </div>
            <div className='card-body'>
            <p className='card-title mb-0 text-center' style={{color: 'rgb(0, 0, 0)', fontSize:'25px', textDecoration: 'line-through'}}>10 800 kr</p>
              <p className='card-text text-center'><i>Lektioner och lån av bil vid prov</i></p>
              {/* <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vintererbjudande 20% rabatt</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i paketet</i></p> */}
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vår och sommarerbjudande!</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>5 % rabatt</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i det här paketet</i></p>
              <br></br>
              <h3 className='card-title mb-0 text-center' style={{ color: 'rgb(172, 3, 11)' }}>10 350 kr</h3>
            </div>
            <ul className='list-group list-group-flush'>
              <li className='list-group-item py-2'> <FontAwesomeIcon icon={faCheck} color='green' /> 10 st körlektioner á 50 min</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Trafikskolans fordon på prov</li>
              {/* <li className='list-group-item py-0'><FontAwesomeIcon icon={faTimes} color='red' /> Körkort nu: Allt - för bil</li> */}
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faTimes} color='red' /> Riskutbildning del 1</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faTimes} color='red' /> Riskutbildning del 2</li>
            </ul>
            <div className='card-footer text-center'>
              {/* <Link to='/e-handel/productDetails/5462' className='btn btn-danger'>Beställ här</Link> */}
              <Link to='/e-handel' className='btn btn-danger'>Beställ här</Link>
            </div>
          </div>
        </div>
        {/* SLUT Körpaket 1 */}

        {/* START Körpaket 2  */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-5'>
          <div className='card shadow mb-2'>
            <div className='card-header themeColorOrginal text-center text-white'>
              <h2 className='mb-2 text-white font-weight-normal'>Körpaket 2 <br /> <p /></h2>
            </div>
            <div className='card-body'>
            <p className='card-title mb-0 text-center' style={{color: 'rgb(0, 0, 0)', fontSize:'25px', textDecoration: 'line-through'}}>19 800 kr</p>
              <p className='card-text text-center'><i>Lektioner och lån av bil vid prov</i></p>
              {/* <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vintererbjudande 20% rabatt</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i paketet</i></p> */}
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vår och sommarerbjudande!</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>10 % rabatt</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i det här paketet</i></p>
              <br></br>
              <h3 className='card-title mb-0 text-center' style={{ color: 'rgb(172, 3, 11)' }}>18 000 kr</h3>
            </div>
            <ul className='list-group list-group-flush'>
              <li className='list-group-item py-2'> <FontAwesomeIcon icon={faCheck} color='green' /> 20 st körlektioner á 50 min</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Trafikskolans fordon på prov</li>
              {/* <li className='list-group-item py-0'><FontAwesomeIcon icon={faTimes} color='red' /> Körkort nu: Allt - för bil</li> */}
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faTimes} color='red' /> Riskutbildning del 1</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faTimes} color='red' /> Riskutbildning del 2</li>
            </ul>
            <div className='card-footer text-center'>
              {/* <Link to='/e-handel/productDetails/5464' className='btn btn-danger'>Beställ här</Link> */}
              <Link to='/e-handel' className='btn btn-danger'>Beställ här</Link>
            </div>
          </div>
        </div>
        {/* SLUT Körpaket 2 */}

        {/* START Körpaket 3  */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'>
          <div className='card shadow mb-2'>
            <div className='card-header themeColorOrginal text-center text-white'>
              <h2 className='mb-2 text-white font-weight-normal'>Körpaket 3 <br /> <p /></h2>
            </div>
            <div className='card-body'>
            <p className='card-title mb-0 text-center' style={{color: 'rgb(0, 0, 0)', fontSize:'25px', textDecoration: 'line-through'}}>28 800 kr</p>
              <p className='card-text text-center'><i>Lektioner och lån av bil vid prov</i></p>
              {/* <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vintererbjudande 20% rabatt</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i paketet</i></p> */}
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>Vår och sommarerbjudande!</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>15 % rabatt</i></p>
              <p className='card-text text-center mb-0' style={{ color: 'rgb(172, 3, 11)' }}><i>på körlektionerna i det här paketet</i></p>
              <br></br>
              <h3 className='card-title mb-0 text-center' style={{ color: 'rgb(172, 3, 11)' }}>24 750 kr</h3>
            </div>
            <ul className='list-group list-group-flush'>
              <li className='list-group-item py-2'> <FontAwesomeIcon icon={faCheck} color='green' /> 30 st körlektioner á 50 min</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Trafikskolans fordon på prov</li>
              {/* <li className='list-group-item py-0'><FontAwesomeIcon icon={faTimes} color='red' /> Körkort nu: Allt - för bil</li> */}
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faTimes} color='red' /> Riskutbildning del 1</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faTimes} color='red' /> Riskutbildning del 2</li>
            </ul>
            <div className='card-footer text-center'>
              {/* <Link to='/e-handel/productDetails/5466' className='btn btn-danger'>Beställ här</Link> */}
              <Link to='/e-handel' className='btn btn-danger'>Beställ här</Link>
            </div>
          </div>
        </div>
        {/* SLUT Körpaket 3 */}

        <div className='col-12 text-center  mt-2'>
          <span className='fa-stack fa-2x'>
            <FontAwesomeIcon icon={faSquare} className='fa-stack-2x' color='rgb(172, 3, 11)' />
            <FontAwesomeIcon icon={faCar} className='fa-stack-1x fa-inverse' color='rgb(220,53,69)' />
          </span>
          <h1>Lektionspaketen</h1>
          <h3 className='card-title mb-0 text-center' style={{ color: 'rgb(172, 3, 11)' }}>Vår och sommarerbjudande!</h3>
          <h3 className='card-title mb-0 text-center' style={{ color: 'rgb(172, 3, 11)' }}>upp till 15 % rabatt</h3>
        </div>

        {/* START Lektionspaketen */}
        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-8 mb-3'>
          <table className='table-borderless table-striped table-hover tabel-responsive '>
            <tbody>
              <tr>
                <td className='pl-2'>3 körlektioner</td>
                <td style={{ color: 'rgb(172, 3, 11)' }}>2 565 kr</td>
                <td className='float-right pr-2'>
                  {/* <Link to='/e-handel/productDetails/5627' className='btn btn-danger'>Beställ här</Link> */}
                  <Link to='/e-handel' className='btn btn-danger'>Beställ här</Link>
                  </td>
              </tr>
              <tr>
                <td className='pl-2'>5 körlektioner</td>
                <td style={{ color: 'rgb(172, 3, 11)' }}>4 275 kr</td>
                <td className='float-right pr-2'>
                  {/* <Link to='/e-handel/productDetails/5628' className='btn btn-danger'>Beställ här</Link> */}
                  <Link to='/e-handel' className='btn btn-danger'>Beställ här</Link>
                  </td>
              </tr>
              <tr>
                <td className='pl-2'>10 körlektioner</td>
                <td style={{ color: 'rgb(172, 3, 11)' }}>8 550 kr</td>
                <td className='float-right pr-2'>
                  {/* <Link to='/e-handel/productDetails/5629' className='btn btn-danger'>Beställ här</Link> */}
                  <Link to='/e-handel' className='btn btn-danger'>Beställ här</Link>
                  </td>
              </tr>
              <tr>
                <td className='pl-2'>20 körlektioner</td>
                <td style={{ color: 'rgb(172, 3, 11)' }}>16 200 kr</td>
                <td className='float-right pr-2'>
                  {/* <Link to='/e-handel/productDetails/5630' className='btn btn-danger'>Beställ här</Link> */}
                  <Link to='/e-handel' className='btn btn-danger'>Beställ här</Link>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* SLUT Lektionspaketen */}

        {/* START Teoripaketen   */}
        {/*  <div className='col-12 text-center  mt-2'><h1>Teoripaketen</h1></div>
       <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4'>
          <div className='card shadow mb-2'>
            <div className='card-header bg-danger text-center text-white'>
              <h2 className='mb-2 text-white'>Körkort nu:<br /> Allt - för bil  </h2>
            </div>
            <div className='card-body'>
              <h3 className='card-title mb-0 text-danger text-center'>790 kr</h3>
              <p className='card-text text-center'><i>Digitalt utbildningsmaterial</i></p>
            </div>
            <ul className='list-group list-group-flush'>
              <li className='list-group-item py-2'> <FontAwesomeIcon icon={faCheck} color='green' /> Digital körkortsbok</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Teorifrågor</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Övningar</li>
              <li className='list-group-item py-0'><FontAwesomeIcon icon={faCheck} color='green' /> Sluttester</li>
            </ul>
            <div className='card-footer text-center'>
              <p><i>Ingår i alla paketen förutom lektionspaketen</i></p>
              <Link to='/e-handel/productDetails/5635' className='btn btn-danger'>Beställ här</Link><br />
            </div>
          </div>
        </div> */}
        {/* SLUT Teoripaketen */}

      </div>
    </div>

  </Layout>
)

export default Paketpriser
